import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta:{
      title: 'Главная'
    } 
  },
  {
    path: '/wallets',
    name: 'wallet',
    component: () => import(/* webpackChunkName: "about" */ '../views/WalletView.vue')
  },
  {
    path: '/demo',
    name: 'demo',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/404',
    name: 'NotFound',
    component: () => import('../views/404/NotFound')
  },,
  {
    path: '/501',
    name: 'InDevelopment',
    component: () => import('../views/501/InDevelopment')
  },
  {
    path: '/:catchAll(.*)', redirect:'404'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
