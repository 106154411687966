<template>
  <body>
  <div class="wrapper">
    <header class="header">
      <div class="header__container">
        <div class="header__body">
          <router-link :to="{name:'home'}" class="header__logo">
            <img src="/img/logo.svg" alt="logo">
          </router-link>
          <router-link :to="{name:'home'}" class="header__logo-mobile">
            <img src="/img/logoM.svg" alt="logo">
          </router-link>
          <nav style="display:none;" class="header__nav">
            <ul class="header__list">
              <li class="header__item">
                <router-link :to="{name:'wallet'}">Бумажник</router-link>
              </li>
              <li class="header__item">
                <router-link :to="{name:'home'}">Платежи</router-link>
              </li>
              <li class="header__item">
                <router-link :to="{name:'demo'}">Запросить демо</router-link>
              </li>
            </ul>
          </nav>
          <div class="header__login login">
            <a href="https://lk.crypto-wave.ru/">Аккаунт</a>
          </div>
          <div class="header__burger">
            <div></div>
          </div>
        </div>
      </div>
    </header>
    <main class="page">
      <section id="top" class="first-page">
        <div class="first-page__container">
          <div class="first-page__body">
            <div class="first-page__content">
              <div class="first-page__title">
                Начните принимать платежи в криптовалюте на своем веб-сайте
              </div>
              <ul class="first-page__list">
                <li class="first-page__item">
                  Безграничные криптовалютные платежи
                </li>
                <li class="first-page__item">
                  Интегрируйте Bitcoin, Ethereum, ERC-20 Tether, ERC-20 USD COIN, XRP на свой веб-сайт в качестве способа оплаты
                </li>
                <li class="first-page__item">
                  Получайте платежи на любую сумму в любой точке мира
                </li>
                <li class="first-page__item">
                  Безопасно обменивайте, храните и тратьте криптовалюты
                </li>
              </ul>
              <a href="https://lk.crypto-wave.ru/" class="first-page__btn btn">
                Создать аккаунт
              </a>
            </div>
            <picture data-da=".first-page__content, 768, last" class="first-page__img-body">
              <div class="first-page__img">
                <img src="/img/FirstPage/FirstSection.svg" alt="">
              </div>
              <div class="first-page__img-mobile">
                <img src="/img/FirstPage/FirstSectionMobile.svg" alt="">
              </div>
            </picture>
          </div>
        </div>
      </section>
      <section class="two-section">
        <div class="two-section__container">
          <div class="two-section__body">
            <div class="two-section__item">
              <div class="two-section__img">
                <img src="/img/FirstPage/Advantager_1.png" alt="">
              </div>
              <div class="two-section__title">
                6 криптовалют
              </div>
              <div class="two-section__text">
                Принимайте на своем веб-сайте основные криптовалюты: Bitcoin, Ethereum, ERC-20 Tether, ERC-20 Tether tron, ERC-20 USD COIN, XRP.
              </div>
            </div>
            <div class="two-section__item">
              <div class="two-section__img">
                <img src="/img/FirstPage/Advantager_2.png" alt="">
              </div>
              <div class="two-section__title">
                Низкая комиссия
              </div>
              <div class="two-section__text">
                Комиссия за транзакцию всего 0,99%. Комиссия может быть либо включена (оплачивается покупателем), либо исключена (оплачивается продавцом) из счета.
              </div>
            </div>
            <div class="two-section__item">
              <div class="two-section__img">
                <img src="/img/FirstPage/Advantager_3.png" alt="">
              </div>
              <div class="two-section__title">
                Безопасные транзакции
              </div>
              <div class="two-section__text">
                Нет возвратных платежей. Отсутствие рисков волатильности (обменный курс фиксируется на 15 минут после создания счета).
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="four-section">
        <div class="four-section__container">
          <div class="four-section__body">
            <div class="four-section__title title">
              Торговые решения
            </div>
            <div class="four-section__content">
              <div data-da=".four-section__info, 768, last" class="four-section__img">
                <img src="/img/FirstPage/TradeOptions.png" alt="">
              </div>
              <div class="four-section__info">
                <div class="four-section__item">
                  <div class="four-section__title-item">
                    Статистика
                  </div>
                  <div class="four-section__text">
                    Контроль над вашими данными. Обзор вашей статистики по доходам, доходам, динамике продаж, платежам по вашему выбору в одном месте.
                  </div>
                </div>
                <div class="four-section__item">
                  <div class="four-section__title-item">
                    Выплаты
                  </div>
                  <div class="four-section__text">
                    Автоматически платите своим клиентам или сотрудникам (например, зарплату) в криптовалюте.
                  </div>
                </div>
<!--                <div class="four-section__item">-->
<!--                  <div class="four-section__title-item">-->
<!--                    Автоматический вывод-->
<!--                  </div>-->
<!--                  <div class="four-section__text">-->
<!--                    Перевод на собственный кошелек без дополнительных комиссий.-->
<!--                    Расписание автовывода необходимой суммы ежедневно/ежемесячно/ежеквартально/годово.-->
<!--                  </div>-->
<!--                </div>-->
                <a href="https://lk.crypto-wave.ru/" class="four-section__btn btn">Создать аккаунт</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="three-section">
        <div class="three-section__container">
          <div class="three-section__body">
            <div class="three-section__title title">
              Выберите варианты интеграции
            </div>
            <div class="three-section__content">
              <div class="three-section__item">
                <div class="three-section__img">
                  <img src="/img/FirstPage/Intagration_1.svg" alt="">
                </div>
                <div class="three-section__title-item">
                  API
                </div>
                <ul class="three-section__list">
                  <li class="three-section__list-item">
                    <a href="#" class="three-section__link">CryptoWave API</a>
                  </li>
<!--                  <li class="three-section__list-item">-->
<!--                    <a href="#" class="three-section__link">Библиотеки API</a>-->
<!--                  </li>-->
                  <li class="three-section__list-item">
                    <a href="#" class="three-section__link">Формат уведомления о мгновенном платеже</a>
                  </li>
                  <li class="three-section__list-item">
                    <a href="#" class="three-section__link">Поддерживаемые способы оплаты</a>
                  </li>
                </ul>
              </div>
              <div class="three-section__item">
                <div class="three-section__img">
                  <img src="/img/FirstPage/Intagration_2.svg" alt="">
                </div>
                <div class="three-section__title-item">
                  Ссылка оплаты
                </div>
                <ul class="three-section__list">
                  <li class="three-section__list-item">
                    <a href="#" class="three-section__link">Как создать ссылку оплаты</a>
                  </li>
                  <li class="three-section__list-item">
                    <a href="#" class="three-section__link">Примеры ссылок оплаты</a>
                  </li>
                </ul>
              </div>
<!--              <div class="three-section__item">-->
<!--                <div class="three-section__img">-->
<!--                  <img src="/img/FirstPage/Intagration_3.svg" alt="">-->
<!--                </div>-->
<!--                <div class="three-section__title-item">-->
<!--                  Плагины корзины покупок-->
<!--                </div>-->
<!--                <ul class="three-section__list">-->
<!--                  <li class="three-section__list-item">-->
<!--                    <a href="https://lk.crypto-wave.ru/" class="three-section__link">Плагин для WordPress</a>-->
<!--                  </li>-->
<!--                </ul>-->
<!--              </div>-->
            </div>
            <div class="three-section__btn btn">
              <a href="https://lk.crypto-wave.ru/">Создать аккаунт</a>
            </div>
          </div>
        </div>
      </section>
      <a href="#top" class="page__up">
        <img src="/img/FirstPage/ArrowUp.svg" alt="">
      </a>
    </main>
    <footer class="footer">
      <div class="footer__container">
        <div class="footer__body">
          <router-link :to="{name:'home'}" class="header__logo">
            <img src="/img/logo.svg" alt="logo">
          </router-link>
          <div class="footer__list-body" style="display: none">
            <ul class="footer__list">
              <li class="footer__list-item">
                <div class="footer__title">Услуги</div>
              </li>
              <li class="footer__list-item">
                <a href="#" class="footer__link">Бумажник</a>
              </li>
              <li class="footer__list-item">
                <a href="#" class="footer__link">Платежи</a>
              </li>
              <li class="footer__list-item">
                <a href="#" class="footer__link">Конвертер валют</a>
              </li>
              <li class="footer__list-item">
                <a href="#" class="footer__link">Цена и условия</a>
              </li>
            </ul>
            <ul class="footer__list">
              <li class="footer__list-item">
                <div class="footer__title">Компания</div>
              </li>
              <li class="footer__list-item">
                <a href="#" class="footer__link">О нас</a>
              </li>
            </ul>
            <ul class="footer__list">
              <li class="footer__list-item">
                <div class="footer__title">Помощь и поддержка</div>
              </li>
              <li class="footer__list-item">
                <a href="#" class="footer__link">Часто задаваемые вопросы</a>
              </li>
              <li class="footer__list-item">
                <a href="#" class="footer__link">Учебники</a>
              </li>
              <li class="footer__list-item">
                <a href="#" class="footer__link">Страница разработчиков</a>
              </li>
            </ul>
            <ul class="footer__list">
              <li class="footer__list-item">
                <div class="footer__title">Юридический</div>
              </li>
              <li class="footer__list-item">
                <a href="#" class="footer__link">Условия эксплуатации</a>
              </li>
              <li class="footer__list-item">
                <a href="#" class="footer__link">Политика конфиденциальности</a>
              </li>
              <li class="footer__list-item">
                <a href="#" class="footer__link">Политика в отношении файлов cookie</a>
              </li>
            </ul>
          </div>
          <div class="footer__login login">
            <a href="https://lk.crypto-wave.ru/">Аккаунт</a>
          </div>
          <div class="footer__copiryght">
            © Copyright 2022
          </div>
        </div>
      </div>
    </footer>
  </div>
  </body>
</template>

<script>
import {da} from "@/views/dynamic_adapt";

export default {
  name: 'HomeView',
  mounted() {
    da.init()
  },
  beforeMount() { document.title = 'Crypto-Wave - ' + this.$route.meta.title },
  beforeUpdate() { document.title = 'Crypto-Wave - ' + this.$route.meta.title }
}
</script>
<style lang="scss">
#app {
  height: 100%;
}
.main {
  flex: 1 0 auto;
}
html{
  scroll-behavior: smooth;
}
.router-link-active{
  color: #33E8D2;
  font-weight: 700;
}
.btn{
  display: inline-block;
  padding: 20px 45px;
  background: linear-gradient(89.58deg, #3DCCFD 8.04%, #3671D9 94.08%);
  box-shadow: 10px 20px 50px rgb(11 55 96 / 20%);
  border-radius: 30px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  transition: linear-gradient .5s ease;
  @media (max-width: 576px) {
    padding: 15px;
  }
  &:hover{
    background: linear-gradient(89.58deg, #28A0EA 8.04%, #2358BD 94.08%);
  }
}
.page{
  position: relative;
  &__up{
    height: 61px;
    width: 61px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 160px;
    bottom: 40px;
    border: 0.5px solid #4B597F;
    border-radius: 50%;
    cursor: pointer;
    @media (max-width: 1366px) {
      right: calc(20px + (160 - 20) * ((100vw - 992px) / (1366 - 992)));
    }
    @media (max-width: 992px) {
      right: 20px;
    }
    @media (max-width: 576px) {
      bottom: -25px;
      background: #ffffff75;
    }
  }
}
.header{
  position: fixed;
  padding: 20px 0;
  width: 100%;
  background: linear-gradient(0deg, #F7FCFF, #F7FCFF), linear-gradient(180deg, rgba(247, 252, 255, 0.2) 0%, rgba(250, 253, 255, 0.2) 92.56%);
  z-index: 10;
  &__burger{
    width: 28px;
    height: 18px;
    margin-left: auto;
    display: none;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    @media (max-width: 576px) {
      display: flex;
    }
    div{
      width: 45%;
      height: 1px;
      background: #4B597F;;
      &:before,
      &:after{
        content:"";
        position: absolute;
        height: 1px;
        background: #4B597F;;
      }
      &:before{
        top: 0;
        right: 0;
        width: 100%;
      }
      &:after{
        bottom: 0;
        right: 0;
        width: 65%;
      }
    }
  }
  @media (max-width: 576px) {
    padding: 10px 0;
  }
  &__body{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__logo{
    width: 105px;
    height: 35px;
    @media (max-width: 576px) {
      display: none;
    }
    img{
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  &__logo-mobile{
    width: 60px;
    height: 30px;
    display: none;
    margin-right: 39px;
    @media (max-width: 576px) {
      display: block;
    }
    img{
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  &__list{
    display: flex;
    a{
      font-size: 14px;
      margin-right: 40px;
    }
  }
}

.first-page{
  margin-bottom: 144px;
  padding-top: 90px;
  @media (max-width: 1366px) {
    margin-bottom:calc(68px + (144 - 68) * ((100vw - 992px) / (1366 - 992)));
  }
  @media (max-width: 992px) {
    margin-bottom: 68px;
    padding-top:calc(100px + (90 - 100) * ((100vw - 768px) / (992 - 768)));
  }
  @media (max-width: 768px) {
    padding-top:100px;
  }

  &__body{
    display: flex;
    align-items: center;
  }
  &__content{
    flex: 0 0 48%;
    margin-right: 60px;
    position: relative;
    z-index: 5;
    @media (max-width: 768px) {
      flex-basis: 100%;
      margin: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  &__title{
    font-weight: 700;
    font-size: 41px;
    line-height: 50px;
    margin-bottom: 32px;
    @media (max-width:1366px) {
      font-size: calc(26px + (41 - 26) * ((100vw - 576px) / (1336 - 576)));
      line-height: calc(28px + (50 - 28) * ((100vw - 992px) / (1336 - 992)));
    }
    @media (max-width: 992px) {
      line-height: normal;
    }
    @media (max-width: 768px) {
      order: 2;
      text-align: center;
    }
    @media (max-width: 576px) {
      font-size: 26px;
    }
  }
  &__list{
    font-size: 17px;
    margin-bottom: 52px;
    @media (max-width: 768px) {
      font-size:calc(14px + (17 - 14) * ((100vw - 320px) / (768 - 320)));
      order: 3;
    }
    @media (max-width: 576px) {
      margin-bottom: 18px;
    }
  }
  &__item{
    padding-left: 20px;
    position: relative;
    display: flex;
    align-items: center;
    &:before{
      content:"";
      position: absolute;
      left: 0;
      height: 7px;
      width: 7px;
      border-radius:50%;
      background: #19D2DE;
      box-shadow: inset 2px -2px 2px rgba(79, 221, 229, 0.5), inset 0px -1px 2px rgba(95, 239, 192, 0.5);
    }
    &:not(:last-child){
      margin-bottom: 28px;
      @media (max-width: 1366px) {
        margin-bottom: calc(15px + (28 - 15) * ((100vw - 992px) / (1336 - 992)));
      }
      @media (max-width: 768px) {
        margin-bottom: 15px;
      }
    }
  }
  &__btn{
    @media (max-width: 1366px) {
      width: 100%;
    }
    @media (max-width: 768px) {
      order: 4;
    }
  }
  &__img-body{
    width: 1065px;
    height: 628px;
    flex: 0 0 auto;
    position: relative;
    @media (max-width: 1366px) {
      width: calc(750px + (1065 - 750) * ((100vw - 992px) / (1336 - 992)));
    }
    @media (max-width: 768px) {
      order: 1;
      width: 85%;
      margin-bottom: 56px;
      height: calc(300px + (500 - 300) * ((100vw - 320px) / (768 - 320)));
    }
    @media (max-width: 576px) {
      width: 100%;
    }
    img{
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  &__img{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: -240px;
    @media (max-width: 1366px) {
      left: calc(-180px + (-240 - -180) * ((100vw - 992px) / (1336 - 992)));
    }
    @media (max-width: 768px) {
      display: none;
    }
  }
  &__img-mobile{
    display: none;
    @media (max-width: 768px) {
      display: block;
      height: 100%;
      width: 100%;
    }
  }
}
.two-section{
  margin-bottom: 144px;
  margin-top: -125px;
  @media (max-width: 1366px) {
    margin-bottom:calc(68px + (144 - 68) * ((100vw - 992px) / (1366 - 992)));
    margin-top:calc(0px + (-125 - 0) * ((100vw - 992px) / (1366 - 992)));
  }
  @media (max-width: 992px) {
    margin-bottom:68px;
    margin-top:0;
  }
  &__body{
    display: flex;
    width: 100%;
    padding: 0 39px;
    column-gap: 94px;
    @media (max-width: 992px) {
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      gap: 20px;
    }
    @media (max-width: 576px) {
      padding: 0;
    }
  }
  &__item{
    flex: 0 1 33.333%;
    text-align: center;
    @media (max-width: 992px) {
      flex-basis: 45%;
    }
    @media (max-width: 576px) {
      flex-basis: 100%;
    }
  }
  &__img{
    margin-bottom: 21px;
    width: 100px;
    height: 100px;
    margin: 0 auto 21px auto;
    img{
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  &__title{
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 21px;
  }
  &__text{
    font-size: 14px;
    line-height: 17px;
  }
}
.three-section{
  margin-bottom: 144px;
  @media (max-width: 992px) {
    margin-bottom: 68px;
  }
  @media (max-width: 576px) {
    margin-bottom: 41px;
  }
  &__title{
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 72px;
    @media (max-width: 768px) {
      margin-bottom: 36px;
    }
  }
  &__body{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__content{
    display: flex;
    justify-content: space-between;
    column-gap: 23px;
    margin-bottom: 61px;
    @media (max-width: 768px) {
      flex-wrap: wrap;
      justify-content: center;
      gap: 23px;
    }
    @media (max-width: 576px) {
      margin-bottom: 41px;
    }
  }
  &__item{
    flex: 0 1 33.333%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0px 4px 20px rgba(186, 194, 235, 0.5);
    border-radius: 10px;
    padding: 35px 30px 45px 30px;
    transition: all .5s ease;
    @media (max-width: 992px) {
      padding: 35px 20px 45px 20px;
    }
    @media (max-width: 768px) {
      flex-basis: 45%;
      height: 371px;
    }
    @media (max-width: 576px) {
      flex-basis: 100%;
    }
    &:hover{
      background: rgba(255, 255, 255, 0.65);
      box-shadow: 0px 4px 30px #BAC2EB;
    }
  }
  &__img{
    height: 90px;
    width: 90px;
    margin-bottom: 20px;
    img{
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  &__title-item{
    margin-bottom: 28px;
    font-weight: 700;
    font-size: 20px;
    @media (max-width: 768px) {
      font-size:calc(18px + (20 - 18) * ((100vw - 320px) / (768 - 320)));
    }
  }
  &__list{
    font-size: 14px;
    align-self: flex-start;
  }
  &__list-item{
    &:not(:last-child){
      margin-bottom: 21px;
    }
    a{
      color: #14D8BD;
    }
  }
  &__btn{
    align-self: center;
    @media (max-width: 576px) {
      width: 100%;
    }
  }
}
.four-section{
  margin-bottom: 144px;
  @media (max-width: 992px) {
    margin-bottom: 68px;
  }
  .title{
    flex: 0 0 100%;
    margin-bottom: 72px;
    @media (max-width: 768px) {
      margin-bottom: 36px;
    }
  }
  &__body{
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 576px) {
      text-align: center;
      margin-bottom: 41px;
    }
  }
  &__img{
    flex: 0 0 auto;
    width: 730px;
    @media (max-width: 1366px) {
      width:calc(380px + (730 - 380) * ((100vw - 768px) / (1366 - 768)));
    }
    @media (max-width: 768px) {
      align-self: center;
      order: 3;
      width: 85%;
      margin-top: 45px;
    }
    @media (max-width: 576px) {
      width: 100%;
    }
    img{
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  &__content{
    display: flex;
    column-gap: 74px;
    @media (max-width: 992px) {
      column-gap:calc(28px + (74 - 28) * ((100vw - 768px) / (992 - 768)));
    }
    @media (max-width: 768px) {
      column-gap: 28px
    }
  }
  &__info{
    flex: 0 0 auto;
    width: 460px;
    @media (max-width: 1366px) {
      width:calc(300px + (460 - 300) * ((100vw - 768px) / (1366 - 768)));
    }
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
  &__item{
    &:not(:last-child){
      margin-bottom: 21px;
    }
  }
  &__title-item{
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 12px;
  }
  &__text{
    font-size: 14px;
    line-height: 17px;
  }
  &__btn{
    margin-top: 45px;
    @media (max-width: 1366px) {
      width: 100%;
    }
    @media (max-width: 768px) {
      order: 4;
    }
  }
}
.footer{
  background: #E3F0F9;
  padding: 70px 0 70px;
  .login{
    @media (max-width: 576px) {
      display: none;
    }
  }
  @media (max-width: 768px) {
    padding-top:calc(40px + (70 - 40) * ((100vw - 320px) / (768 - 320)));
    padding-bottom:calc(40px + (70 - 40) * ((100vw - 320px) / (768 - 320)));
  }
  &__body{
    display: flex;
    justify-content: space-between;
    column-gap: 40px;
    font-size: 14px;
    position: relative;
    height: 75px;
    @media (max-width: 576px) {
      justify-content: center;
    }
  }
  &__list-body{
    display: flex;
    justify-content: space-between;
    column-gap: 40px;
  }
  &__logo{
    margin-right: auto;
    @media (max-width: 576px) {
      margin: 0 auto;
    }
  }
  &__list{
    flex: 0 0 140px;

    &:nth-child(5){
      flex: 0 0 210px;
    }
    &:nth-child(4){
      flex: 0 0 210px;
    }
  }
  &__list-item{
    margin-bottom: 26px;
    a{
      color: #7481A5AB;
    }
  }
  &__copiryght{
    position: absolute;
    bottom: 0px;
  }
}
.title{
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  @media (max-width: 768px) {
    font-size: calc(25px + (28 - 24) * ((100vw - 320px) / (768 - 320)));
  }
  @media (max-width: 576px) {
    text-align: center;
  }
}

.login{
  font-size: 14px;
  border: 1px solid #4B597F;
  border-radius: 30px;
  height: max-content;
  background: transparent;
  a{
    display: inline-block;
    padding: 12px 29px;
    color: #4B597F ;
    @media (max-width: 576px) {
      padding: 10px 29px;
    }
  }
  &:hover{
    background: linear-gradient(0deg, #33E8D2, #33E8D2), linear-gradient(89.58deg, #05E6C3 8.04%, #1D73C4 133.89%);
    border-color: transparent;
    a{
      color: #ffffff;
    }
  }
}

</style>
